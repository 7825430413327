<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-light.png`)"
    />

    <v-img class="misc-tree" :src="require('@/assets/images/misc/tree-4.png')"></v-img>

    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">ページが見つかりません</span>
        <v-icon color="warning">
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>

      <div class="misc-character d-flex justify-center">
        <v-img max-width="700" :src="require('@/assets/images/3d-characters/error.png')"></v-img>
      </div>

      <v-btn color="primary" to="/" class="mb-4">
        Back to home
      </v-btn>
    </div>
  </div>
</template>

<script>
import {
  mdiAlert,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/misc.scss';
</style>
